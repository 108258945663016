export interface IFundListReport {
  FILTER_FUND: string;

  TABLE_END_DATE: string;
  TABLE_FUND_NAME: string;
  TABLE_ID: string;

  TABLE_NUMBER: string;
  TABLE_ERC_NUMBER: string;
  TABLE_ERC_YEAR: string;
  TABLE_ERC_TITLE: string;
  TABLE_EAD_RECEIVED: string;
  TABLE_STABLE_STORAGE: string;
  TABLE_PRIVATE_CONSIST: string;
  TABLE_ACT_NUMBER: string;
  TABLE_ACT_DATE: string;
}

export const fields: IFundListReport = {
  FILTER_FUND: "fundCode",

  TABLE_END_DATE: "tmp2",
  TABLE_FUND_NAME: "tmp3",
  TABLE_ID: "id",

  TABLE_NUMBER: "tmp4",
  TABLE_ERC_NUMBER: "tmp5",
  TABLE_ERC_YEAR: "tmp6",
  TABLE_ERC_TITLE: "tmp7",
  TABLE_EAD_RECEIVED: "tmp8",
  TABLE_STABLE_STORAGE: "tmp9",
  TABLE_PRIVATE_CONSIST: "tmp10",
  TABLE_ACT_NUMBER: "tmp11",
  TABLE_ACT_DATE: "tmp12",
};
