import { IReportElement } from "@monorepo/reports/src/views/FundListView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundListReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertTopApiItemToUi = (item: IReportElement): Record<Values, string> => ({
  [fields.TABLE_END_DATE]: item.fundDate || "",
  [fields.TABLE_FUND_NAME]: item.fundName || "",
  [fields.TABLE_ID]: item.id || "",
});

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.TABLE_ID]: item.id || "",
  [fields.TABLE_NUMBER]: item.index || "",
  [fields.TABLE_ERC_NUMBER]: item.number,
  [fields.TABLE_ERC_YEAR]: item.year || "",
  [fields.TABLE_ERC_TITLE]: item.header || "",
  [fields.TABLE_EAD_RECEIVED]: item.receivedEadCount,
  [fields.TABLE_PRIVATE_CONSIST]: item.byPersonnelEadCount || 0,
  [fields.TABLE_STABLE_STORAGE]: item.foreverEadCount,
  [fields.TABLE_ACT_NUMBER]: item.actNumber || "",
  [fields.TABLE_ACT_DATE]: item.actDate || "",
});
