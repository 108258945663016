import { v4 as uuid } from "uuid";
// import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { fields } from "./fields";

export const topTable = [
  {
    text: "Крайние даты каждого названия фонда",
    tooltip: "Первая и последняя дата поступления ЭАД",
    defaultWidth: 340,
    value: fields.TABLE_END_DATE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Название фонда",
    tooltip: "Название фонда",
    defaultWidth: 340,
    value: fields.TABLE_FUND_NAME,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];

export const tableHeaders = [
  // headerCheckboxObject(),
  {
    text: "№ п/п",
    tooltip: "Номер по порядку",
    defaultWidth: 80,
    value: fields.TABLE_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Номер описи дел",
    tooltip: "Номер описи дел",
    defaultWidth: 140,
    value: fields.TABLE_ERC_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Год описи дел",
    tooltip: "Год описи дел",
    defaultWidth: 140,
    value: fields.TABLE_ERC_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Заголовок описи дел",
    tooltip: "Заголовок описи дел",
    defaultWidth: 240,
    value: fields.TABLE_ERC_TITLE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Поступило ЭАД",
    tooltip: "Количество ЭАД, поступивших на хранение",
    defaultWidth: 130,
    value: fields.TABLE_EAD_RECEIVED,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Постоянного хранения",
    tooltip: "Из них ЭАД постоянного хранения",
    defaultWidth: 180,
    value: fields.TABLE_STABLE_STORAGE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "По личному составу",
    tooltip: "Их них ЭАД по личному составу",
    defaultWidth: 140,
    value: fields.TABLE_PRIVATE_CONSIST,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Номер акта",
    tooltip: "Номер акта приёма-передачи дел",
    defaultWidth: 120,
    value: fields.TABLE_ACT_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Дата акта",
    tooltip: "Дата утверждения акта приёма-передачи дел",
    defaultWidth: 150,
    value: fields.TABLE_ACT_DATE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
