import { fields } from "./fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export const filtersElements = [
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getAutocompleteFilterByCode",
    className: "filter-fund mt-4",
    width: 250,
    educationId: educationIds.listViewSettings,
  },
];
